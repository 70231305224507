
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="row">
      <div class="col-md-12">
        <a class="btn btn-danger float-right" href="/apps/createRule">Add Rule</a>
      </div>
    </div>

    <br/>
    <b-table striped hover :items="rules" :fields="columns">
      <template v-slot:cell(fundingPartners)="data">
        <ul>
          <li v-for="fundingPartner in data.item.fundingPartners">{{ fundingPartner.fundingPartner.name }}</li>
        </ul>
      </template>
      <template v-slot:cell(status)="data">
        <b-dropdown size="sm" text="Small" variant="outline-dark">
          <template #button-content>
            <span class="badge badge-success" v-if="data.item.status == 'ACTIVE'"> Active </span>
            <span class="badge badge-danger" v-else-if="data.item.status == 'INACTIVE'"> In Active </span>
          </template>
          <b-dropdown-item > Select Status</b-dropdown-item>
          <b-dropdown-item  @click="openUpdateRulesStatusDialog(data.item,'ACTIVE')"><span class="badge badge-success"> Active </span></b-dropdown-item>
          <b-dropdown-item  @click="openUpdateRulesStatusDialog(data.item,'INACTIVE')"><span  class="badge badge-danger"> Inactive </span></b-dropdown-item>
        </b-dropdown>

      </template>
      <template v-slot:cell(options)="data">
        <b-dropdown text="options">
          <b-dropdown-item :href="'/apps/editRule/'+data.item.id"  >Edit</b-dropdown-item>

        </b-dropdown>
      </template>
    </b-table>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CodeInput from "vue-verification-code-input"
  import Multiselect from 'vue-multiselect';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'


export default {
  data() {
    return {
      phoneNumberDuplicate:"",
      phoneNumber:"",
      offset: 4,
      pref:"254",
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:["created","ruleName","marketingText",{
        key: 'validityStartDate',
        label:'startDate',
        sortable: false,
      },{
        key: 'validityEndDate',
        label:'endDate',
        sortable: false,
      },
        "fundingPartners","status","options"],
      rules:[],
      outlets:[],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      loyaltyRuleStatus:"",
      customers:[],
      loyaltyRule:{}
    };
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  mounted (){
    this.getOutletData();
    this.getRulesData();
  },
  methods: {
    ...mapActions(["getOutlets","searchCustomerByPhoneNumber","createSMS","getSMSCampaigns","deleteCampaign","getRules","updateRuleStatus"]),
    openUpdateRulesStatusDialog(loyaltyRule, loyaltyRuleStatus) {
      this.loyaltyRule = loyaltyRule;
      this.loyaltyRuleStatus = loyaltyRuleStatus;

      let self = this;
      this.$bvModal.msgBoxConfirm('Update rule status for '+loyaltyRule.ruleName +' ?')
          .then(value => {

            if(value){
              self.submitUpdateLoyaltyRuleStatus();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },
    openDeleteCampaign(sms){

      this.sms = sms;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.sms.title +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteCampaign();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    submitUpdateLoyaltyRuleStatus() {
      this.isLoading = true;

      let self = this;

      let ruleData = {
        ruleId: this.loyaltyRule.id,
        status: this.loyaltyRuleStatus
      }

      this.updateRuleStatus(ruleData).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Rule status changed", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getRulesData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },

    confirmDeleteCampaign(){
      this.isLoading = true;

      let self = this;


      this.deleteCampaign(this.sms).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Campaign deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getCampaignsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    searchCustomer(){

      let self = this;


      if(this.phoneNumber != ""){

        self.isLoading = true;

        this.searchCustomerByPhoneNumber({phone_number: this.phoneNumber})
          .then(function (customers) {


            self.isLoading = false;

            self.customers.push(...customers);

            console.log(self.customers);
          })
          .catch(function (error) {

            self.isLoading = false;

          })
      }
      else {
        alert("Please enter phone number")
      }

    },


    getOutletData(){
      let self = this;
      this.getOutlets()
        .then(function (outlets) {



          self.outlets = outlets;


        })
        .catch(function (error) {

        })
    },

    changePage: function (page) {
      this.pagination.current_page = page;
      this.getCustomersData();
    },

    getRulesData(){
      let self = this;
      this.getRules()
        .then(function (rules) {
          self.rules = rules;

        })
        .catch(function (error) {

        })
    },


    validateBeforeSubmitNewCampaign(){

      let self = this;

      self.isLoading = true;

      this.sms.customers = this.sms.customers.map(function (customer){
          return customer.id;
      });

      this.sms.outlets = this.sms.outlets.map(function (outlet){
        return outlet.id;
      });


      this.createSMS( this.sms)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Campaign added.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCampaignsData();

        })
        .catch(function (error) {

          self.isLoading = false;

        })
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
